import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import styles from './privacy-policy.module.scss';

export default ({ data }) => {
  return (
    <Layout>
      <div className={'columns'}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contentbox}`}>
            <h1 className={`title`}>Our privacy policy</h1>
            <p className={`content is-size-7`}>
              The family of companies comprised of Ingenovis Health, Inc.;
              Fastaff, LLC; Healthcare Support Staffing, LLC; Trustaff
              Management, LLC; U.S. Nursing Corporation, CardioSolution, LLC,
              and their subsidiaries and affiliates (collectively, the “Company”
              or “we” or “our”) respects your privacy and is committed to
              protecting it through our compliance with this Privacy Policy
              (“Policy”).
            </p>
            <p className={`content is-size-7`}>
              This Policy describes the types of information we may collect from
              you or that you may provide when you visit the Company’s websites,
              including: IngenovisHealth.com, Trustaff.com, My.Trustaff.com,
              Estaff365.com, mobile.er.Trustaff.com, Fastaff.com, usnursing.com,
              healthcaresupport.com, myportal.fastaff.com, cardiosolution.com,
              and fastaff.erecruitcloud.com (collectively, the “Websites”), or
              when you download and use one of our mobile applications (the
              Websites and mobile applications are, collectively, the “Online
              Services”). This Policy further describes our practices for
              collecting, using, maintaining, protecting, and disclosing that
              information. Employees, independent contractors, and job
              applicants should review the separate privacy notices we provide
              to you, or make available to you, when you submit an application,
              regarding our collection and use of personal information in the
              employment/contracting/applicant context. If there are any
              conflicts between this Policy and the separate privacy notice, the
              separate notice controls.
            </p>
            <p className={`content is-size-7`}>
              By accessing or using the Online Services, you consent to the
              collection and use of your personal information by the Company in
              accordance with this Policy. If you do not agree to the terms of
              this Policy, please do not use the Online Services.
            </p>
            <p className={`content is-size-7`}>
              If we change this Policy, we will advise you of material changes
              through postings on our Websites, and/or email to the primary
              email address you provide to us (if any) or push notifications
              through our mobile applications. Your continued access to or use
              of the Online Services after we provide notice will mean that you
              accept the changes. You are responsible for ensuring we have an
              up-to-date active and deliverable email address for you if you
              wish to receive change notices via email, and for periodically
              visiting our Websites and this Policy to check for any changes.
            </p>
            <p className={`content is-size-7`}>
              In accordance with all applicable laws, this Policy applies to all
              personal information we collect about you from any source,
              including:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                Through the Online Services or any of your other interactions
                with the Company;
              </li>
              <li>
                In email, text, and other electronic messages between you and
                us, including through communications made through the Online
                Services;
              </li>
              <li>
                Through mobile and desktop applications you download from the
                Websites or online application platforms (such as Google Play or
                the App Store), which provide dedicated non-browser-based
                interaction between you and the Online Services; and
              </li>
              <li>
                When you interact with our advertising and applications on
                third-party websites and services, if those applications or
                advertising include links to this Policy.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              This Policy does not apply to information collected by any third
              party, including through any application or content (including
              advertising) that may link to or be accessible from or on the
              Online Services. As permitted by law, this Policy does not apply
              to personal information pertaining to employees, independent
              contractors, job applicants and individuals communicating with us
              in the business-to-business context, and collected in those
              contexts.
            </p>
            <p className={`content is-size-7`}>
              Please read this Policy carefully to understand our practices
              regarding your personal information. If you do not agree with our
              practices, your choice is not to use our Online Services. By
              accessing or using the Online Services, you agree to this Policy.
              The Company reserves all rights.
            </p>

            <h2 className={`title is-5 has-text-info`}>
              Information We Collect and Share or Disclose, and Sources of the
              Information
            </h2>
            <p className={`content is-size-7`}>
              We collect several types of personal information from and about
              users of our Online Services. For purposes of this Policy,
              personal information is broadly defined to include, among other
              things, all information that can be directly or indirectly linked
              to an individual or household. In all cases, personal information
              does not include de-identified information, aggregate information
              that cannot be linked to a particular individual or household, or
              pseudonymized information.
            </p>
            <p className={`content is-size-7`}>
              Currently and for the 12-month period prior to the Effective Date
              of this Policy (except as specified below), we have collected and
              shared the categories of information listed below. This listing
              does not apply to employees, contractors, job applicants or
              business contacts:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>Identifiers</strong>, e.g., name, email address, postal
                address, phone number, online identifiers (such as user name),
                passwords when you set up an account, or internet protocol
                address. We collect this information when you provide it to us
                and automatically when you use our Online Services.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              We may share this information with affiliates, subsidiaries,
              agents or service providers.
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>
                  Internet and other online activity, and device information
                </strong>
                , such as IP address; device ID; browser information and
                history; cookie information; operating systems; time stamps; the
                pages you request; the path you take on the Online Services;
                user settings; your use of our Online Services; search queries
                conducted on the Online Services; details of your use of the
                Online Services, including traffic data, location data, logs,
                and other communication data and the resources that you access
                and use on the Online Services. We collect this information
                automatically when you use our Online Services.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              We may share this information with affiliates, subsidiaries,
              agents or service providers.
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>Geolocation data</strong> is collected when you provide
                it to us, or may be collected automatically by third party
                analytics companies.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              We may share this information with affiliates, subsidiaries,
              agents or service providers.
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>Information you provide to us</strong>, including if you
                use any of services available through our Online Services,
                records and copies of your correspondence, and information and
                documentation you choose to upload using our document
                repository.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              We may share this information with affiliates, subsidiaries,
              agents or service providers.
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>Inferences</strong> drawn from information provided to
                us by you or third parties, and information that we collect
                automatically when you use our Online Services.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              We may share this information with affiliates, subsidiaries,
              agents or service providers.
            </p>
            <p className={`content is-size-7`}>
              In addition, we have collected personal information from our
              employees, independent contractors and job applicants in the
              course of those individuals’ acting in their capacity as an
              employee, contractor or job applicant, as specified in notices
              provided or made available to them and any agreements with them,
              including in connection with registration to use the Nurse Portal
              login. We also have collected information from individuals who
              interact with us on behalf of a business, non-profit organization
              or governmental agency solely in the context of conducting
              business or providing or receiving a service, including
              information provided to us at the time such individuals register
              to use our services if the individual represents a hospital.
            </p>
            <p className={`content is-size-7`}>
              You also may provide information to be published or displayed
              (hereinafter, “posted”) on public areas of the Websites, or
              transmitted to other users of the Websites or third parties
              (collectively, “User Contributions”). Your User Contributions are
              posted on and transmitted to others at your own risk. Although we
              limit access to certain pages, please be aware that no security
              measures are perfect or impenetrable. Additionally, we cannot
              control the actions of other users of the Websites with whom you
              may choose to share your User Contributions. We cannot guarantee
              that third party vendors engaged by us who have access to User
              Contributions in connection with the services they perform will
              not provide access to User Contributions without authorization. We
              are not responsible for the actions or omissions of third party
              vendors. Therefore, we cannot and do not guarantee that your User
              Contributions will not be viewed by unauthorized persons.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Automated Data Collection Technologies
            </h2>
            <p className={`content is-size-7`}>
              We may use automatic data collection technologies to collect
              information about your online activities over time and across
              third-party websites or other online services. The information we
              collect automatically is statistical data and does not include
              personal information. It helps us to improve our Online Services
              and to deliver a better and more personalized service, including
              by enabling us to:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about your preferences, allowing us to
                customize our Websites according to your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Websites.</li>
            </ul>
            <p className={`content is-size-7`}>
              The technologies we use for this automatic data collection may
              include:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>Cookies (or browser cookies)</strong> A cookie is a
                small file placed on the hard drive of your computer. You may
                refuse to accept browser cookies by activating the appropriate
                setting on your browser. However, if you select this setting you
                may be unable to access certain parts of our Websites. Unless
                you have adjusted your browser setting so that it will refuse
                cookies, our system will issue cookies when you direct your
                browser to our Websites.
              </li>
              <li>
                <strong>Flash Cookies</strong> Certain features of our Online
                Services may use local stored objects (or Flash cookies) to
                collect and store information about your preferences and
                navigation to, from, and on our Online Services. Flash cookies
                are not managed by the same browser settings as are used for
                browser cookies.
              </li>
              <li>
                <strong>Web Beacons</strong> Pages of the Websites and our
                e-mails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages or opened an email and for other
                related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).
              </li>
            </ul>

            <h2 className={`title is-5 has-text-info`}>
              Third-Party Use of Cookies and Other Tracking Technologies
            </h2>
            <p className={`content is-size-7`}>
              Some content or applications, including advertisements, on the
              Online Services are served by third-parties, including
              advertisers, ad networks and servers, content providers, and
              application providers. These third parties may use cookies alone
              or in conjunction with web beacons or other tracking technologies
              to collect information about you when you use our website, and
              that allows for tracking over time and across websites.
            </p>
            <p className={`content is-size-7`}>
              We do not control these third parties’ tracking technologies or
              how they may be used. If you have any questions about an
              advertisement or other targeted content, you should contact the
              responsible provider directly.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              How We Use Your Information – Our Business Purpose for Collecting
              the Information
            </h2>
            <p className={`content is-size-7`}>
              We use information that we collect about you or that you provide
              to us, including any personal information:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>To present our Services and their contents to you.</li>
              <li>
                To provide you with information, products, or services that you
                request from us.
              </li>
              <li>To fulfill any purpose for which you provide it.</li>
              <li>
                To provide you with notices about your relationship with any of
                us.
              </li>
              <li>
                To notify you about changes to our Services or any products or
                services we offer or provide though it.
              </li>
              <li>
                To allow you to participate in interactive features on our
                Services.
              </li>
              <li>
                To provide you with information about job placements or
                assignments.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
            <p className={`content is-size-7`}>
              The listing above regarding our use of personal information does
              not apply to personal information collected from our employees,
              applicants for employment, contractors or business contacts in the
              context of those relationships. We encourage employees,
              independent contractors and job applicants to review the separate
              privacy notices provided to them.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Additional Disclosures of Your Personal Information
            </h2>
            <p className={`content is-size-7`}>
              In addition to the disclosures described above, we may disclose
              personal information that we collect or that you provide to us as
              follows:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                To comply with any court order, law, subpoenas, search warrants,
                judicial proceedings, legal process or law enforcement measures,
                including to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our{' '}
                <Link to="/terms-of-use/">terms of use</Link> and other
                agreements.
              </li>
              <li>
                To establish or exercise our legal rights, or to defend against
                legal claims.
              </li>
              <li>
                To investigate, prevent, or take action regarding alleged or
                actual illegal activities, or as otherwise required by law.
              </li>
              <li>
                o comply with applicable laws, including laws pertaining to
                taxes.
              </li>
              <li>
                To administer a promotion, survey or other feature of our
                Services.
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of the Company, our customers,
                or others.
              </li>
              <li>
                We may also share information about you for any other purposes
                disclosed to you at the time we collect your information or with
                your consent.
              </li>
              <li>
                To a prospective buyer or buyer or other successor in the event
                of a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of the
                Company’s assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which
                personal information held by the Company is among the assets
                transferred, including in connection with any due diligence
                conducted in anticipation of such a transaction.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              We may disclose aggregated information about our users and
              information that does not identify any individual, without
              restriction.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              No Sales of Personal Information
            </h2>
            <p className={`content is-size-7`}>
              We have not sold personal information to third parties during the
              12 months prior to the Effective Date of this Policy, and do not
              plan to do so without further notice (with the exception of a sale
              in connection with the sale or transfer of the business or our
              assets, as noted in the section above about Additional
              Disclosures).
            </p>
            <h2 className={`title is-5 has-text-info`}>Security Measures</h2>
            <p className={`content is-size-7`}>
              We have implemented measures designed to secure your personal
              information and information you upload to the Repository from
              accidental loss and from unauthorized access, use, alteration, and
              disclosure. No security measures are perfect or impenetrable. The
              safety and security of your information also depends on you. Where
              we have given you (or where you have chosen) a password for access
              to a part of one of our Websites, you are responsible for keeping
              this password confidential. We ask you not to share your password
              with anyone. Unfortunately, the transmission of information via
              the internet is not completely secure. Although we work hard to
              protect your personal information and information in the
              Repository from unauthorized access, alteration, disclosure, and
              loss, we cannot guarantee the security of your personal
              information transmitted to or stored through our Websites or that
              information will not be altered or lost. Any transmission, storage
              or loss of personal or other information is at your own risk. You
              should keep backup copies of all information stored on the
              Repository and check that materials on the Repository are
              accurate. We are not responsible for circumvention of any privacy
              settings or security measures contained on the Websites.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Your Rights With Respect to Personal Information We Collect
            </h2>
            <p className={`content is-size-7`}>
              If you are a California resident, you have certain rights with
              respect to your information under the California Consumer Privacy
              Act (“CCPA”). Those rights and how you may exercise them are
              described below. Those rights are not absolute and the Company
              reserves all available rights in this regard. For example, the
              rights described below to not extend to personal information
              collected in the employment/independent contracting context
              (including job applicants) or business-to-business context. We
              are, however, pleased to extend these rights to all individuals
              regardless of state of residence, subject to the same exclusions
              that pertain to California residents.
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                <strong>Non-Discrimination</strong> You have the right to be
                free from discrimination for exercising your rights to know or
                delete.
              </li>{' '}
              <li>
                <strong>Right to Know</strong> You may request that we provide
                you a list of the categories of personal information we have
                collected about you over the last 12 months, the categories of
                sources from which it was collected, the business or commercial
                purpose for collecting or selling the information, and the
                categories of third parties to whom we disclosed or sold that
                information. You may also request that we provide you a copy of
                the specific pieces of personal information we have collected
                about you in the past 12 months. You may make a request to know
                up to two times in a 12-month period, subject to limitations
                described in the law. For a list of general categories of
                information that we have collected and shared in the past 12
                months, see the listing above under “
                <strong>
                  Information We Collect and Share or Disclose, and Sources of
                  the Information
                </strong>
                .”
              </li>
              <li>
                <strong>Right to Delete</strong> You may request that we delete
                any personal information that we have collected from you, with
                the exception of information that the law allows us to keep.
                When we respond to your request to delete, we will explain what
                (if any) information we have kept and why.
              </li>
              <li>
                <strong>How to Make a Request to Know or Delete</strong> You may
                make a request to know or delete by emailing{' '}
                <a href="mailto:privacy@ingenovishealth.com">
                  Privacy@ingenovishealth.com
                </a>
                ,{' '}
                <a href="mailto:privacyrequests@fastaff.com">
                  PrivacyRequests@fastaff.com
                </a>
                , <a href="mailto:privacy@Trustaff.com">Privacy@Trustaff.com</a>
                , or submitting your request online on our Website at{' '}
                <a
                  href="http://response.fastaff.com/Privacy"
                  rel="noreferrer"
                  target="_blank"
                >
                  http://response.fastaff.com/Privacy
                </a>
                ,{' '}
                <Link to="/privacy-request/">
                  https://www.trustaff.com/privacy-request
                </Link>
                , or by calling our toll-free number at{' '}
                <strong>1-800-736-8773</strong> or <strong>877-880-3046</strong>
                . When you make a request, we will take steps to verify your
                identity before responding. This is to protect your information.
                We will ask you to provide us your email and physical address.
                If you maintain an account with us, these must match the
                addresses connected to your account. We will then send you a
                physical letter to this address with a one-time code. You must
                email us this code from the email address you provided to us.
                Once you do, we will respond to your request.
              </li>
              <li>
                <strong>Authorized Agent</strong> You may designate an
                authorized agent to make requests on your behalf. We will
                require verification that you did, in fact, authorize the agent.
                Unless the law requires otherwise, your authorized agent must
                provide contact details for you. We will contact you to confirm
                that you authorized the agent. Once you confirm, we will
                promptly respond to the rights request.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              As permitted by law, the rights described above do not apply to
              all personal information that we collect. For example, we collect
              information from our employees, independent contractors and job
              applicants in the course of those individuals’ acting in those
              capacities, consistent with notices provided to them and any
              agreements with them. We also collect information from individuals
              who interact with us on behalf of a business, non-profit
              organization or governmental agency solely in the context of
              conducting business or providing or receiving a service. These and
              other categories of information are excluded from the rights
              described above. the Company reserves its right to rely upon all
              exclusions and other provisions allowed by law.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Minors Under Age 18 and Children Under Age 13
            </h2>
            <p className={`content is-size-7`}>
              Minors under 18 years of age may have the personal information and
              content that they have posted on the Websites removed by sending
              an email to{' '}
              <a href="mailto:privacy@ingenovishealth.com">
                Privacy@ingenovishealth.com
              </a>{' '}
              ,{' '}
              <a href="mailto:privacyrequests@fastaff.com">
                PrivacyRequests@fastaff.com
              </a>{' '}
              ,{' '}
              <a href="mailto:support@healthcaresupport.com">
                support@healthcaresupport.com
              </a>{' '}
              or <a href="privacy@Trustaff.com">Privacy@Trustaff.com</a>,
              requesting removal. Please note that, while we make reasonable
              efforts to comply with such requests, deletion of your personal
              information does not ensure complete and comprehensive removal of
              that content or information from all sites, services or systems.
            </p>
            <p className={`content is-size-7`}>
              Our Online Services are not intended for children under 13 years
              of age or any minors. No one under age 13 may provide any
              information to or through the Online Services. We do not knowingly
              collect personal information from children under 13. If you are
              under 13, do not use or provide any information on any of the
              Online Services or on or through any of its features/register on
              any of the Online Services or provide any information about
              yourself to us, including your name, address, telephone number,
              email address, or any screen name or user name you may use. If we
              learn we have collected or received personal information from a
              child under 13 without verification of parental consent, we will
              delete that information. If you believe we might have any
              information from or about a child under 13, please contact us.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              “Do not track” Signals
            </h2>
            <p className={`content is-size-7`}>
              Some Internet browsers include the ability to transmit “Do Not
              Track” signals. We do not process or respond to “Do Not Track”
              signals.
            </p>
            <h2 className={`title is-5 has-text-info`}>Contact Information</h2>
            <p className={`content is-size-7`}>
              To ask questions or comment about this Policy and our privacy
              practices, contact us at:
            </p>
            <p className={`content is-size-7`}>
              LEGAL DEPARTMENT
              <br />
              <strong>Ingenovis Health</strong>
              <br />
              5700 South Quebec Street
              <br />
              Suite 300
              <br />
              Greenwood Village, CO 80111
              <br />
              <a href="mailto:legal@ingenovishealth.com">
                legal@ingenovishealth.com
              </a>{' '}
              / <strong>800-736-8773</strong>
              <br />
              <a href="mailto:legal@fastaff.com">legal@fastaff.com</a> /{' '}
              <strong>800-736-8773</strong>
              <br />
              <a href="mailto:legal@Trustaff.com">legal@Trustaff.com</a> /{' '}
              <strong>877-880-3046</strong>
            </p>
            <p className={`content is-size-7`}>
              Updated effective date: 9-15-2021
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
